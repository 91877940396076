import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Container from 'components/Container'
import PeopleIcon from 'components/icons/People'
import jungImg from 'static/jung.jpg'
import sungImg from 'static/sung.jpg'
import IconContainer from 'components/icons/Container'

const Block = ({ color, className, ...props }) => (
  <div className={`bg-${color} m-0 p-0 ${className}`} {...props} />
)

const AboutUsContainer = ({ className, ...props }) => (
  <div className={`flex py-12 ${className}`} {...props} />
)

const DoctorDescriptionContainer = props => (
  <div className="flex-1 my-4" {...props} />
)

class AboutUsPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet title="Oregon City Smiles - About Us" />
        <Block className="pt-4" color="lightSky">
          <IconContainer>
            <PeopleIcon color="white" size="2rem" />
          </IconContainer>
          <p className="text-2xl text-center">
            Meet the Oregon City Smiles dental team
          </p>
        </Block>
        <Block id="sung" color="lightSky">
          <Container>
            <AboutUsContainer className="flex-col sm:flex-col md:flex-row">
              <div className="flex-initial mr-8">
                <div class="max-w-sm rounded overflow-hidden shadow-lg">
                  <img class="w-full" src={sungImg} alt="Sung Yeon Ji, DDS" />
                  <div class="px-6 py-4">
                    <p class="text-gray-700 text-xl">Sung Yeon Ji, DDS</p>
                  </div>
                </div>
              </div>

              <DoctorDescriptionContainer>
                <p className="text-lg leading-normal px-4 mb-4">
                  Dr. Sung Yeon Ji, DDS (DR. SUNG), received her DDS from New
                  York University College of Dentistry in 2013. She has been
                  working as a general dentist in private practices. Dr. Sung
                  believes in building long term relationships with her patients
                  in a gentle and comfortable environment. She is innovative,
                  proactive and comprehensive in her approach to dental care for
                  her patients. Patient centered care is always the priority and
                  you are always in control when it comes to your dental
                  treatments. Dr. Sung will take the time you need to understand
                  all aspects of your dental care.
                </p>

                <p className="text-lg leading-normal px-4">
                  Dr. Ji values building long term relationships with her
                  patients and providing quality dentistry in a gentle,
                  comfortable environment. She became interested in dentistry in
                  her teens, she loves her profession more and more everyday.
                </p>
              </DoctorDescriptionContainer>
            </AboutUsContainer>
          </Container>
        </Block>

        <Block id="jung" color="lighterGrey">
          <Container>
            <AboutUsContainer className="flex-col sm:flex-row md:flex-row-reverse">
              <div className="flex-initial ml-8">
                <div class="max-w-sm rounded overflow-hidden shadow-lg">
                  <img class="w-full" src={jungImg} alt="Jung Hun Ji, DDS" />
                  <div class="px-6 py-4">
                    <p class="text-gray-700 text-xl">Jung Hun Ji, DDS</p>
                  </div>
                </div>
              </div>

              <DoctorDescriptionContainer>
                <p className="text-lg leading-normal px-4 mb-4">
                  Dr. Jung Hun Ji (DR. JI) graduated from Loma Linda University
                  with Doctorate of Dental Surgery in 2014. He started
                  practicing general dentistry in Reno, Nevada, after graduating
                  dental school. After working in Reno for few years, he decided
                  to move back to Oregon where his family is. He has been
                  practicing in the area as an associate before he started this
                  private practice. In his spare time, Dr. Ji enjoys hiking and
                  watching movies and snowboarding.
                </p>

                <p className="text-lg leading-normal px-4">
                  Dr. Ji regularly pursues continuing education courses in Oral
                  surgery, Endodontics, Periodontics, Dental implants and more
                  to stay on the cutting edge. Dr. Ji is a proud member of
                  American dental association, Oregon Dental Association and
                  Clackamas County Dental Society. Drs. Ji’s family moved to
                  Oregon from South Korea in their teens. Both Dr. Sung and Dr.
                  Ji grew up in Eugene, Oregon and graduated from University of
                  Oregon, both are huge Oregon Ducks’ fans.
                </p>
              </DoctorDescriptionContainer>
            </AboutUsContainer>
          </Container>
        </Block>
      </Layout>
    )
  }
}

export default AboutUsPage
